.HatOnly {
    background: url(..//Images/HatOnly.png) no-repeat center;
    background-size: cover; 
    background-repeat: no-repeat;
}

.fontborder-dark {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.fontborder-light {
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
