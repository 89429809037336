.btn-primary {
  background-color: #E91D25 !important;
  border-color: #E91D25 !important;
}

.btn-primary:hover {
background-color: #f32f36 !important;
border-color: #f32f36 !important;
}

.spacer {
  height: 80px;
  background-color: transparent;
}

@media screen and (max-width: 900px) {
  .hide-display{
    display:none !important;
  }
}